<mat-toolbar class="p-0 mat-elevation-z2 blue-500-bg">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/bh.svg" />
                </div>
            </div>

            <!-- <div class="px-8 px-md-16">
                <bh-shortcuts [navigation]="navigation"></bh-shortcuts>
            </div> -->
        </div>
        <div fxFlex class="px-12">{{ pageTitle }}</div>
        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <div class="toolbar-separator"></div>
            <div class=' mt-12' *ngIf='ps.isNational'>
                <app-warehouse-switcher class='warehouse-switcher-container'></app-warehouse-switcher>

            </div>
            <div class="toolbar-separator"></div>

            <app-profile></app-profile>

            <!-- <div class="toolbar-separator"></div> -->
            <!-- <bh-search-bar (input)="search($event)"></bh-search-bar> -->

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle quick panel">
                <mat-icon [matBadge]="_notificationCount" class="secondary-text">notifications</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>