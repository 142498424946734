<div class="navbar-header primary-blue-bg">
    <div class="logo">
        <img src="assets/images/logos/logo.svg" alt="" />
        <!-- <span class="logo-text">EDWARD SNELL & CO.</span> -->
    </div>

    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg
        *ngIf="!sidebarOpened">
        <mat-icon class="grey-50-fg">menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button>
</div>

<div class="navbar-content py-24 bg-image primary-blue-bg" bhPerfectScrollbar
    [bhPerfectScrollbarOptions]="{ suppressScrollX: true }">
    <bh-navigation layout="vertical"></bh-navigation>
</div>