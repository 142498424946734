import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { BhConfigService } from "@bh/services/config.service";
import { BhNavigationService } from "@bh/components/navigation/navigation.service";
import { BhSidebarService } from "@bh/components/sidebar/sidebar.service";
import { BhSplashScreenService } from "@bh/services/splash-screen.service";

import { navigation } from "app/navigation/navigation";
import { AuthService } from "./pages/auth/auth.service";


@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    bhConfig: any;
    navigation: any;
    ready = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {BhConfigService} _bhConfigService
     * @param {BhNavigationService} _bhNavigationService
     * @param {BhSidebarService} _bhSidebarService
     * @param {BhSplashScreenService} _bhSplashScreenService
     * @param {BhTranslationLoaderService} _bhTranslationLoaderService
     * @param {Platform} _platform
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _bhConfigService: BhConfigService,
        private _bhNavigationService: BhNavigationService,
        private _bhSidebarService: BhSidebarService,
        private _bhSplashScreenService: BhSplashScreenService,
        private _platform: Platform,
    ) {   

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.setNavigation();
        // this.authService.isLoggedIn
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((res) => {
        //         if (res) {
        //             this.setNavigation();
        //             this.ready = true;
        //         }
        //     });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._bhConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.bhConfig = config;

                // Boxed
                if (this.bhConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.bhConfig.colorTheme);
            });
    }

    // async setNavigation() {
    //     const user = await Auth.currentAuthenticatedUser();
    //     const token = user.signInUserSession.idToken.jwtToken;
    //     const role = jwtDecode(token)["cognito:groups"][0];
    //     if (role) {
    //         const profile = await DataStore.query(User, (u) =>
    //             u.cognitoId("eq", role)
    //         );

    //         // Filter out parent items
    //         this.navigation = navigation.filter((n) => {
    //             if (n.roles.includes(role)) {
    //                 if (n.children) {
    //                     n.children = n.children.filter((c) =>
    //                         c.roles.includes(role)
    //                     );
    //                 }
    //                 return n;
    //             }
    //         });
    //         // this._bhNavigationService.unregister("main");
    //         // Register the navigation to the service
    //         this._bhNavigationService.register("main", this.navigation);

    //         // Set the main navigation as our current navigation
    //         this._bhNavigationService.setCurrentNavigation("main");
    //     }
    // }

    async setNavigation() {
        // Filter out parent items
        this.navigation = navigation
        // Register the navigation to the service
        this._bhNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this._bhNavigationService.setCurrentNavigation("main");
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._bhSidebarService.getSidebar(key).toggleOpen();
    }
}
