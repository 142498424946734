// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const NotificationType = {
  "EMAIL": "EMAIL",
  "PUSHNOTIFICATION": "PUSHNOTIFICATION"
};

const TransferRequestStatus = {
  "REQUESTED": "REQUESTED",
  "COLLECTED": "COLLECTED",
  "COMPLETED": "COMPLETED"
};

const CustomerZone = {
  "BLM": "BLM",
  "CPT": "CPT",
  "DBN": "DBN",
  "EL": "EL",
  "JNB": "JNB",
  "PE": "PE",
  "GRG": "GRG"
};

const CustClass = {
  "A": "A",
  "B": "B",
  "C": "C",
  "D": "D",
  "H": "H",
  "L": "L",
  "Z": "Z"
};

const LocationType = {
  "NATIONAL": "NATIONAL",
  "REGIONAL": "REGIONAL"
};

const UserRole = {
  "TECH": "TECH",
  "ADMIN": "ADMIN",
  "MOBILE": "MOBILE",
  "REGIONALMANAGER": "REGIONALMANAGER"
};

const TaskType = {
  "STATUS_CHECK": "STATUS_CHECK",
  "UPLIFTMENT": "UPLIFTMENT",
  "IMPLEMENT": "IMPLEMENT",
  "TRANSFER": "TRANSFER"
};

const Status = {
  "NEW": "NEW",
  "GOOD": "GOOD",
  "BAD": "BAD",
  "MISSING": "MISSING",
  "DECOMMISSIONED": "DECOMMISSIONED"
};

const TaskStatus = {
  "OPEN": "OPEN",
  "COMPLETED": "COMPLETED",
  "BUSY": "BUSY"
};

const Owner = {
  "REP": "REP",
  "CUSTOMER": "CUSTOMER",
  "WAREHOUSE": "WAREHOUSE",
  "THIRDPARTY": "THIRDPARTY"
};

const ResultStatus = {
  "PENDING": "PENDING",
  "COMPLETE": "COMPLETE",
  "ERROR": "ERROR"
};

const { Task, TaskItem, TransferRequest, Notification, Customer, Brand, ThirdParty, User, Element, Warehouse, Stock, StockMovement, TaskLog, ReferenceNumber, TransferItem, TaskNextStep, StepFunctionResult } = initSchema(schema);

export {
  Task,
  TaskItem,
  TransferRequest,
  Notification,
  Customer,
  Brand,
  ThirdParty,
  User,
  Element,
  Warehouse,
  Stock,
  StockMovement,
  TaskLog,
  ReferenceNumber,
  NotificationType,
  TransferRequestStatus,
  CustomerZone,
  CustClass,
  LocationType,
  UserRole,
  TaskType,
  Status,
  TaskStatus,
  Owner,
  ResultStatus,
  TransferItem,
  TaskNextStep,
  StepFunctionResult
};