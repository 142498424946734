import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import awsconfig from './aws-exports';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { DataStore } from 'aws-amplify';

awsconfig['graphql_headers'] = async () => ({
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
});

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
DataStore.configure({
    maxRecordsToSync: 1000000,
    syncPageSize: 500,
});
if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
