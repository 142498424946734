import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComsService } from '@bh/components/coms/coms.service';
import { AuthService } from 'app/pages/auth/auth.service';
import { ProfileService } from 'app/services/profile.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
    version = environment.appVersion;
    prevVersion = localStorage.getItem('version');
    constructor(
        private authService: AuthService,
        private router: Router,
        public ps: ProfileService,
        private coms: ComsService
    ) {
        this.authService.isLoggedIn.subscribe((res) => {
            if (res) {
                this.ps.getProfile();
            }
        });
    }

    ngOnInit(): void {
        if (!this.prevVersion || this.prevVersion !== this.version) {
            this.coms.showBottomMsg(
                'A new Version was detected, Syncing all your data',
                'warning'
            );
            localStorage.setItem('version', this.version);
            this.router.navigate(['sync']);
        }
    }

    logout() {
        this.authService.signOut();
    }

    goToMyProfile() {
        this.router.navigateByUrl('/my-profile');
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
    }
}
