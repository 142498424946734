import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'app/services/profile.service';
import { DataStore } from 'aws-amplify';
import { LocationType, Warehouse } from 'models';

@Component({
    selector: 'app-warehouse-switcher',
    templateUrl: './warehouse-switcher.component.html',
    styleUrls: ['./warehouse-switcher.component.scss'],
})
export class WarehouseSwitcherComponent implements OnInit {
    selectedWarehouse: Warehouse;
    warehouses: Warehouse[];
    loading = true;
    constructor(private ps: ProfileService) {}

    ngOnInit(): void {
        this.getWarehouses();
    }

    async getWarehouses() {
        this.warehouses = await DataStore.query(Warehouse);
        this.selectedWarehouse = this.warehouses.find(
            (w) => w.id === this.ps.selectedWarehouseId
        );
        this.loading = false;
    }

    public compareWith(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    changeWarehouse(warehouse: Warehouse) {
        this.ps.changeWarehouse(warehouse.id);
    }
}
