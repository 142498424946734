<ng-container *ngIf="!item.hidden">
    <div class="group-title" [ngClass]="item.classes">
        <span class="grey-500-fg">{{
            item.title
            }}</span>
    </div>

    <div class="group-items">
        <ng-container *ngFor="let item of item.children">
            <bh-nav-vertical-group *ngIf="item.type == 'group'" [item]="item"></bh-nav-vertical-group>
            <bh-nav-vertical-collapsable *ngIf="item.type == 'collapsable'" [item]="item"></bh-nav-vertical-collapsable>
            <bh-nav-vertical-item *ngIf="item.type == 'item'" [item]="item"></bh-nav-vertical-item>
        </ng-container>
    </div>
</ng-container>