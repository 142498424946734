<button mat-button [matMenuTriggerFor]="userMenu" class="user-button" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="center center">
        <div>
            <span class="username mr-12" fxHide fxShow.gt-sm>Welcome, {{ps.profile?.name}} {{ps.profile?.surname}}</span>

            <div class="sm-text">
                <strong>
                    {{ps.profile?.warehouse?.name}} - 
                </strong>
                ({{ps.profile?.role}})
            </div>
        </div>

        <mat-icon>account_circle</mat-icon>
        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
    </div>


</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="before">
    <button mat-menu-item (click)="goToMyProfile()">
        <mat-icon>account_circle</mat-icon>
        <span>My Profile</span>
    </button>

    <button mat-menu-item class="" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
    </button>
    <mat-divider></mat-divider>
    <div class="text-center version-text pt-4 grey-100-bg">v: {{ version }}</div>
</mat-menu>